/* Hide scrollbar for Chrome, Safari, and Opera */
.css-1oqqzyl-MuiContainer-root::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for Firefox */
.css-1oqqzyl-MuiContainer-root {
  scrollbar-width: none;
}
/* Hide scrollbar for IE and Edge */
.css-1oqqzyl-MuiContainer-root {
  -ms-overflow-style: none;
}
body, h1, h2, h3, p, ul, li, div {
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  background: linear-gradient(to bottom, #FFFFFF 0%, #87CEEB 25%, #B0C4DE 45%, #0B3D02 97%, #0B3D02 100%);
}
.css-1oqqzyl-MuiContainer-root {
  max-width: 100% !important;
  display:flex !important;
  flex-direction: column;
  min-height:100%;
  flex-grow:1;
  Padding:0 !important;
}
body, html {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
} 
.toolbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
}
.nav-buttons {
  display: flex;
  gap: 16px;
}
.backgroundImage {
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  transform: translateY(6vh);
  overflow:hidden;
  margin-bottom: 50px;
}
.pagebody {
  flex-grow: 1;
  Padding:1vw 2vw !important;
}
.footer {
  background-color: #2E8B57;
  color: white;
  text-align: center;
  padding: 3px;
  margin-left:2vw;
  margin-right:2vw;
  width: calc(100% - 4vw);
  margin-top: 1vh;
  z-index: 1;
  flex-shrink: 0;
  border-top-left-radius: 24px; 
  border-top-right-radius: 24px;
}


